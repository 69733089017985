import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';

export default {
	name: 'UserPermissions',
	data: () => ({
		isValidFormDialog: false,
		show: false,
		editedItem: {
			password: '',
			confirmPassword: '',
		},
		defaultItem: {
			password: '',
			confirmPassword: '',
		},
		passwordRules: [
			(v) => !!v || 'Campo requerido',
			(v) => (v && v.length >= 6) || 'La contraseña debe tener mínimo 6 caracteres',
			(v) =>
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/.test(v) ||
				'La contraseña debe tener una letra, un número y un carácter especial (@$!%*#?&)',
		],
		errors: [],
		isLoadingSave: false,
	}),
	computed: {
		confirmPasswordRules() {
			return [
				(v) => !!v || 'Campo requerido',
				(v) =>
					(v && v.length >= 6) ||
					'La contraseña debe tener mínimo 6 caracteres',
				(v) => v == this.editedItem.password || 'La contraseña no coincide',
			];
		},
	},
	created() {},
	mounted: function () {
		//$EventBus.$emit('showSubNav', true);
	},
	watch: {},
	methods: {
		async save() {
			if (this.$refs.form.validate()) {
				this.isLoadingSave = true;
				this.error = [];
				const formData = {
					password: this.editedItem.password,
					confirm_password: this.editedItem.confirmPassword,
				};
				const { ok, error } = await this.$store.dispatch(
					'user/updatePassword',
					formData
				);
				if (ok) {
					this.resetForm();
					$EventBus.$emit(
						'showSnack',
						'success',
						'La contraseña se ha actualizado satisfactoriamente'
					);
					this.$router.push({ name: 'Dashboard' });
				} else {
					this.errors = Array.isArray(error?.data?.message)
						? error?.data?.message
						: [(error?.data?.message || 'Error en el servicio').toString()];
				}
				this.isLoadingSave = false;
			}
		},
		resetForm() {
			this.$refs.form.reset();
		},
	},
	components: { ValidationAlert },
};
